import React from 'react';
import {MenuItem, Select} from "../../../common/components";

const SignageTypeSelection = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      value={props.currentValue}
      onChange={handleChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      {props.signageTypes.map((useType) => (
        <MenuItem key={useType} value={useType}>
          {useType}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SignageTypeSelection;