/*
 * Copyright 2018 General Code
 */

import debounce from "lodash/debounce";
import * as PropTypes from "prop-types";
import React, {Fragment} from "react";

import {Dialog, FormControl, InputAdornment, InputLabel, MenuItem, PrimaryButton, Select, TextField} from "../../common/components";
import {PageControl, PageControlContent} from "../../common/components/pagecontrol";
import {FilterList as FilterListIcon} from "../../common/components/icons";
import {If} from "../../common/containers";
import Sticky from "react-sticky-el";

const debounceWait = 300;

class QuestionFilters extends React.Component {

  static propTypes = {
    filters: PropTypes.shape({
      needsReviewFilter: PropTypes.bool,
      assignedFilter: PropTypes.bool,
      assignedToFilter: PropTypes.string,
      deferrableFilter: PropTypes.bool,
      deferFilter: PropTypes.bool,
      statusFilter: PropTypes.string,
      filterText: PropTypes.string,
    }),
    reviewers: PropTypes.object.isRequired,
    filterQuestions: PropTypes.func.isRequired,
    questions: PropTypes.object.isRequired,
    displayedQuestionIds: PropTypes.array.isRequired,
    bodyTop: PropTypes.number.isRequired,
    analysis: PropTypes.object.isRequired,
    analysisId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hasFinalizePermission: PropTypes.bool.isRequired,
    hasCompletePermission: PropTypes.bool.isRequired,
    hasPrintPermission: PropTypes.bool.isRequired,
    hasStatusReportPermission: PropTypes.bool.isRequired,
    isNewNav: PropTypes.bool.isRequired,
    clearFilters: PropTypes.func.isRequired,
    checkAnalysis: PropTypes.func.isRequired,
    clearAnalysisMessage: PropTypes.func.isRequired,
    completeAnalysis: PropTypes.func.isRequired,
    displayMoreQuestions: PropTypes.func.isRequired,
    expandAllQuestions: PropTypes.func.isRequired,
    collapseAllQuestions: PropTypes.func.isRequired,
    finalizeAnalysis: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    const {filters: {filterText}} = this.props;
    this.state = {
      filterText: filterText,
      confirmOpen: false
    };
    this.handleFilterTextUpdate = debounce(this.handleFilterTextUpdate, debounceWait);
  }

  handleFilterTextChange = (event) => {
    const value = event.target.value;
    this.setState({filterText: value});
    this.handleFilterTextUpdate(value);
  };

  handleFilterTextUpdate = (filterText) => {
    const {filters, filterQuestions} = this.props;
    filterQuestions({...filters, filterText});
  };

  handleNeedsReviewChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const needsReviewFilter = (event.target.value === '' ? null : event.target.value === 'true');
    filterQuestions({...filters, needsReviewFilter});
  };

  handleAssignedChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const assignedFilter = (event.target.value === '' ? null : event.target.value === 'true');
    filterQuestions({...filters, assignedFilter});
  };

  handleAssignedToChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const assignedToFilter = (event.target.value === '' ? null : event.target.value);
    filterQuestions({...filters, assignedToFilter});
  };

  handleDeferrableChange = (event) => {
    const {filters, filterQuestions} = this.props;
    let deferrableFilter = (event.target.value === '' ? null : event.target.value === 'true');
    let deferFilter = null;
    if (event.target.value === 'defer') {
      deferrableFilter = null;
      deferFilter = true;
    }
    filterQuestions({...filters, deferrableFilter, deferFilter});
  };

  handleStatusChange = (event) => {
    const {filters, filterQuestions} = this.props;
    const statusFilter = (event.target.value === '' ? null : event.target.value);
    filterQuestions({...filters, statusFilter});
  };

  handleRefresh = () => {
    const {filters, filterQuestions} = this.props;
    filterQuestions({...filters});
  };

  handleClear = () => {
    const {clearFilters} = this.props;
    this.setState({filterText: ''});
    clearFilters();
    window.scrollTo(0, 0);
  };

  handleSticky = () => {
    let isStickyDiv = document.getElementsByClassName(" sticky");
    let isCurrentlySticky = true;
    if(isStickyDiv.length > 0){
      isCurrentlySticky = false;
      this.setState({isCurrentlySticky: false});
    } else {
      this.setState({isCurrentlySticky: true});
    }

    let statusContainer = document.getElementById("statusFilterSelect");
    if(statusContainer.style.display === "none" || !isCurrentlySticky) {
      statusContainer.style.display = "inline-flex";
    } else {
      statusContainer.style.display = "none";
    }

    let needsReviewContainer = document.getElementById("needsReviewFilterSelect");
    if(needsReviewContainer.style.display === "none" || !isCurrentlySticky) {
      needsReviewContainer.style.display = "inline-flex";
    } else {
      needsReviewContainer.style.display = "none";
    }

    let assignedContainer = document.getElementById("assignedFilterSelect");
    if(assignedContainer.style.display === "none" || !isCurrentlySticky) {
      assignedContainer.style.display = "inline-flex";
    } else {
      assignedContainer.style.display = "none";
    }

    let assignedToContainer = document.getElementById("assignedToFilterSelect");
    if(assignedToContainer.style.display === "none" || !isCurrentlySticky) {
      assignedToContainer.style.display = "inline-flex";
    } else {
      assignedToContainer.style.display = "none";
    }

    let deferrableContainer = document.getElementById("deferrableFilterSelect");
    if(deferrableContainer.style.display === "none" || !isCurrentlySticky) {
      deferrableContainer.style.display = "inline-flex";
    } else {
      deferrableContainer.style.display = "none";
    }

    let filterContainer = document.getElementsByClassName("filterInputContainer")[0];
    if(filterContainer.style.display === "none" || !isCurrentlySticky) {
      filterContainer.style.display = "inline-flex";
    } else {
      filterContainer.style.display = "none";
    }
  };

  componentDidMount() {
    window.addEventListener('keypress', this.handleFind);
    window.addEventListener('keydown', this.handleFind);
  };

  handleFind(e) {
    if((e.ctrlKey && e.keyCode === 70) || (e.ctrlKey && e.keyCode === 71)){
      e.preventDefault();
      window.scrollTo(0, 0);
      document.getElementsByClassName("filterInputContainer")[0].style.display = "inline-flex";
      document.getElementById("filterInput").focus();
    }
  };

  handleExpandAll = () => {
    const {analysisId, expandAllQuestions} = this.props;
    expandAllQuestions(analysisId);
  };

  handleCollapseAll = () => {
    const {analysisId, collapseAllQuestions} = this.props;
    collapseAllQuestions(analysisId);
  };

  shouldExpandEnable = () => {
    const {analysis, questions} = this.props;
    let enable = false;
    analysis.questionIds.forEach(id => {
      let q = questions[id];
      if (!q.isOpen) {
        enable = true;
      }
    });
    return enable;
  };

  shouldCollapseEnable = () => {
    const {analysis, questions} = this.props;
    let enable = false;
    analysis.questionIds.forEach(id => {
      let q = questions[id];
      if (q.isOpen) {
        enable = true;
      }
    });
    return enable;
  };

  handleAnalysisCheckClick = () => {
    const {checkAnalysis, analysis} = this.props;
    checkAnalysis(analysis.id);
  };

  handleAnalysisSubmitClick = () => {
    const {finalizeAnalysis, analysis} = this.props;
    finalizeAnalysis(analysis.id);
  };

  handleDownload = () => {
    window.location.href = window.location.origin + window.location.pathname + "/print" + window.location.search;
  }

  cancelComplete = () => {
    this.setState({confirmOpen: false});
  };

  handleComplete = () => {
    this.setState({confirmOpen: true});
  };

  handleCompleteAnalysis = () => {
    this.cancelComplete();
    const {analysis, completeAnalysis} = this.props;
    completeAnalysis(analysis.id);
  };

  render() {
    const {
      filters: {needsReviewFilter, assignedFilter, assignedToFilter, deferrableFilter, statusFilter, deferFilter},
      reviewers, questions, displayedQuestionIds, hasCompletePermission, hasFinalizePermission, analysis,
      hasPrintPermission, isNewNav, bodyTop
    } = this.props;
    const {filterText, confirmOpen} = this.state;
    if (!analysis) return null;
    return (
      <Fragment>
        <If test={hasCompletePermission || (!analysis.finalized && hasFinalizePermission) || hasPrintPermission}>
          <PageControl id="actionButtonsPageControl">
            <PageControlContent>
              <If test={isNewNav}>
                <div className="finalizeButtonContainer">
                  <If test={hasCompletePermission}>
                    <Dialog
                      title="Complete Analysis?"
                      open={confirmOpen}
                      onCancel={this.cancelComplete}
                      onSubmit={this.handleCompleteAnalysis}
                    >
                      Completing this analysis will remove it from view. Complete analysis?
                    </Dialog>
                  </If>
                  <If test={!analysis.finalized && hasFinalizePermission}>
                    <PrimaryButton
                      variant="contained"
                      onClick={this.handleAnalysisCheckClick}
                      title="Check this analysis for errors."
                      color="primary"
                    >
                      Check
                    </PrimaryButton>
                    <PrimaryButton
                      variant="contained"
                      onClick={this.handleAnalysisSubmitClick}
                      disabled={!analysis.checked || Object.keys(analysis.errorLinks).length > 0}
                      title={(!analysis.checked ? "This analysis must be checked before it can be submitted."
                        : (Object.keys(analysis.errorLinks).length > 0 ? "All errors in this analysis must be corrected before it can be submitted."
                          : "Submit this analysis to General Code."))}
                    >
                      Submit
                    </PrimaryButton>
                  </If>
                  <If test={analysis.finalized}>
                    <If test={hasCompletePermission}>
                      <PrimaryButton
                        variant="contained"
                        className="completeButton"
                        onClick={this.handleComplete}
                        title="Complete this analysis."
                      >
                        Complete
                      </PrimaryButton>
                    </If>
                  </If>
                  <If test={hasPrintPermission}>
                    <PrimaryButton
                      variant="contained"
                      onClick={this.handleDownload}
                      className="downloadIa2Button"
                      title="Download analysis"
                    >
                      Download
                    </PrimaryButton>
                  </If>
                </div>
              </If>
            </PageControlContent>
          </PageControl>
        </If>
        <PageControl>
          <PageControlContent>
            <If test={isNewNav}>
              <div id="questionFiltersContent">
                <div className="questionExpansionControls">
                  <PrimaryButton
                    variant="contained"
                    onClick={this.handleExpandAll}
                    disabled={!this.shouldExpandEnable()}
                    className="expandAllButton"
                    title="Expand all questions."
                  >
                    Expand All
                  </PrimaryButton>
                  <PrimaryButton
                    variant="contained"
                    onClick={this.handleCollapseAll}
                    disabled={!this.shouldCollapseEnable()}
                    className="collapseAllButton"
                    title="Collapse all questions."
                  >
                    Collapse All
                  </PrimaryButton>
                </div>
                <div id="questionFilters">
                  <FormControl id="statusFilterSelect" className="formSelectControl">
                    <InputLabel id="statusFilterSelectInput-label" htmlFor="statusFilterSelectInput">
                      Status
                    </InputLabel>
                    <Select
                      value={!statusFilter ? "" : statusFilter}
                      onChange={this.handleStatusChange}
                      inputProps={{
                        id: 'statusFilterSelectInput',
                        name: 'status'
                      }}
                    >
                      <MenuItem value=""/>
                      <MenuItem value="UNANSWERED">Unanswered</MenuItem>
                      <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                      <MenuItem value="COMPLETE">Complete</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl id="needsReviewFilterSelect" className="formSelectControl">
                    <InputLabel id="needsReviewFilterSelectInput-label" htmlFor="needsReviewFilterSelectInput">
                      Needs Review
                    </InputLabel>
                    <Select
                      value={needsReviewFilter === null ? "" : needsReviewFilter}
                      onChange={this.handleNeedsReviewChange}
                      inputProps={{
                        id: 'needsReviewFilterSelectInput',
                        name: 'needsReview'
                      }}
                    >
                      <MenuItem value=""/>
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl id="assignedFilterSelect" className="formSelectControl">
                    <InputLabel id="assignedFilterSelectInput-label" htmlFor="assignedFilterSelectInput">
                      Assigned
                    </InputLabel>
                    <Select
                      value={assignedFilter === null ? "" : assignedFilter}
                      onChange={this.handleAssignedChange}
                      inputProps={{
                        id: 'assignedFilterSelectInput',
                        name: 'assigned'
                      }}
                    >
                      <MenuItem value=""/>
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl id="assignedToFilterSelect" className="formSelectControl">
                    <InputLabel id="assignedToFilterSelectInput-label" htmlFor="assignedToFilterSelectInput">
                      Assigned To
                    </InputLabel>
                    <Select
                      value={assignedToFilter === null ? "" : assignedToFilter}
                      onChange={this.handleAssignedToChange}
                      inputProps={{
                        id: 'assignedToFilterSelectInput',
                        name: 'assignedTo'
                      }}
                    >
                      <MenuItem value=""/>
                      {Object.values(reviewers).map((reviewer) => (<MenuItem key={reviewer.username} value={reviewer.username}>{reviewer.displayName}</MenuItem>))}
                    </Select>
                  </FormControl>
                  <FormControl id="deferrableFilterSelect" className="formSelectControl">
                    <InputLabel id="deferrableFilterSelectInput-label" htmlFor="deferrableFilterSelectInput">
                      Defer
                    </InputLabel>

                    <Select
                      value={deferrableFilter === null ? deferFilter === null ? "" : 'defer' : deferrableFilter}
                      onChange={this.handleDeferrableChange}
                      inputProps={{
                        id: 'deferrableFilterSelectInput',
                        name: 'deferrable'
                      }}
                    >
                      <MenuItem value=""/>
                      <MenuItem value="true">Deferrable</MenuItem>
                      <MenuItem value="false">Non Deferrable</MenuItem>
                      <MenuItem value="defer">Deferred</MenuItem>
                    </Select>
                  </FormControl>
                  <div id="questionFiltersActions">
                    <PrimaryButton variant="contained" className="refreshFiltersButton" onClick={this.handleRefresh}>Refresh</PrimaryButton>
                    <PrimaryButton variant="contained" className="clearFiltersButton" onClick={this.handleClear}>Clear</PrimaryButton>
                  </div>
                  <TextField
                    className="filterInputContainer"
                    value={filterText === null ? "" : filterText}
                    onChange={this.handleFilterTextChange}
                    InputProps={{
                      startAdornment: <InputAdornment position="start"><label htmlFor="filterInput"><FilterListIcon
                        title="Filter the questions listing"/></label></InputAdornment>,
                      id: "filterInput",
                      title: "Filter the questions listing",
                      'aria-label': "Filter the questions listing"
                    }}
                  />
                </div>
                <div className="filterLabel">
                  Displaying {(displayedQuestionIds === null ? Object.keys(questions).length : displayedQuestionIds.length)} of {Object.keys(questions).length} questions
                </div>
              </div>
            </If>
            <If test={!isNewNav}>
              <Sticky className="questionFiltersSticky" stickyStyle={{zIndex: 1, top: bodyTop + 42}} onFixedToggle={this.handleSticky}>
                <div id="questionFiltersBox">
                  <div id="questionFiltersContent" className="questionFiltersContentShadow">
                    <If test={hasCompletePermission}>
                      <Dialog
                        title="Complete Analysis?"
                        open={confirmOpen}
                        onCancel={this.cancelComplete}
                        onSubmit={this.handleCompleteAnalysis}
                      >
                        Completing this analysis will remove it from view. Complete analysis?
                      </Dialog>
                    </If>
                    <div className="questionExpansionControls">
                      <PrimaryButton
                        variant="contained"
                        onClick={this.handleCollapseAll}
                        disabled={!this.shouldCollapseEnable()}
                        className="collapseAllButton"
                        title="Collapse all questions."
                      >
                        Collapse All
                      </PrimaryButton>
                      <PrimaryButton
                        variant="contained"
                        onClick={this.handleExpandAll}
                        disabled={!this.shouldExpandEnable()}
                        className="expandAllButton"
                        title="Expand all questions."
                      >
                        Expand All
                      </PrimaryButton>
                    </div>
                    <div className="finalizeButtonContainer">
                      <If test={!analysis.finalized && hasFinalizePermission}>
                        <PrimaryButton
                          variant="contained"
                          onClick={this.handleAnalysisCheckClick}
                          title="Check this analysis for errors."
                          color="primary"
                        >
                          Check
                        </PrimaryButton>
                        <PrimaryButton
                          variant="contained"
                          onClick={this.handleAnalysisSubmitClick}
                          disabled={!analysis.checked || Object.keys(analysis.errorLinks).length > 0}
                          title={(!analysis.checked ? "This analysis must be checked before it can be submitted."
                            : (Object.keys(analysis.errorLinks).length > 0 ? "All errors in this analysis must be corrected before it can be submitted."
                              : "Submit this analysis to General Code."))}
                        >
                          Submit
                        </PrimaryButton>
                      </If>
                      <If test={analysis.finalized}>
                        <If test={hasCompletePermission}>
                          <PrimaryButton
                            variant="contained"
                            className="completeButton"
                            onClick={this.handleComplete}
                            title="Complete this analysis."
                          >
                            Complete
                          </PrimaryButton>
                        </If>
                      </If>
                      <If test={hasPrintPermission}>
                        <PrimaryButton
                          variant="contained"
                          onClick={this.handleDownload}
                          className="downloadIa2Button"
                          title="Download analysis"
                        >
                          Download
                        </PrimaryButton>
                      </If>
                    </div>
                    <div id="questionFilters">
                      <FormControl id="statusFilterSelect" className="formSelectControl">
                        <InputLabel id="statusFilterSelectInput-label" htmlFor="statusFilterSelectInput">
                          Status
                        </InputLabel>
                        <Select
                          value={!statusFilter ? "" : statusFilter}
                          onChange={this.handleStatusChange}
                          inputProps={{
                            id: 'statusFilterSelectInput',
                            name: 'status'
                          }}
                        >
                          <MenuItem value=""/>
                          <MenuItem value="UNANSWERED">Unanswered</MenuItem>
                          <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                          <MenuItem value="COMPLETE">Complete</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl id="needsReviewFilterSelect" className="formSelectControl">
                        <InputLabel id="needsReviewFilterSelectInput-label" htmlFor="needsReviewFilterSelectInput">
                          Needs Review
                        </InputLabel>
                        <Select
                          value={needsReviewFilter === null ? "" : needsReviewFilter}
                          onChange={this.handleNeedsReviewChange}
                          inputProps={{
                            id: 'needsReviewFilterSelectInput',
                            name: 'needsReview'
                          }}
                        >
                          <MenuItem value=""/>
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl id="assignedFilterSelect" className="formSelectControl">
                        <InputLabel id="assignedFilterSelectInput-label" htmlFor="assignedFilterSelectInput">
                          Assigned
                        </InputLabel>
                        <Select
                          value={assignedFilter === null ? "" : assignedFilter}
                          onChange={this.handleAssignedChange}
                          inputProps={{
                            id: 'assignedFilterSelectInput',
                            name: 'assigned'
                          }}
                        >
                          <MenuItem value=""/>
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl id="assignedToFilterSelect" className="formSelectControl">
                        <InputLabel id="assignedToFilterSelectInput-label" htmlFor="assignedToFilterSelectInput">
                          Assigned To
                        </InputLabel>
                        <Select
                          value={assignedToFilter === null ? "" : assignedToFilter}
                          onChange={this.handleAssignedToChange}
                          inputProps={{
                            id: 'assignedToFilterSelectInput',
                            name: 'assignedTo'
                          }}
                        >
                          <MenuItem value=""/>
                          {Object.values(reviewers).map((reviewer) => (<MenuItem key={reviewer.username} value={reviewer.username}>{reviewer.displayName}</MenuItem>))}
                        </Select>
                      </FormControl>
                      <FormControl id="deferrableFilterSelect" className="formSelectControl">
                        <InputLabel id="deferrableFilterSelectInput-label" htmlFor="deferrableFilterSelectInput">
                          Deferrable
                        </InputLabel>

                        <Select
                          value={deferrableFilter === null ? "" : deferrableFilter}
                          onChange={this.handleDeferrableChange}
                          inputProps={{
                            id: 'deferrableFilterSelectInput',
                            name: 'deferrable'
                          }}
                        >
                          <MenuItem value=""/>
                          <MenuItem value="true">Deferrable</MenuItem>
                          <MenuItem value="false">Non Deferrable</MenuItem>
                        </Select>
                      </FormControl>
                      <div id="questionFiltersActions">
                        <PrimaryButton variant="contained" className="refreshFiltersButton" onClick={this.handleRefresh}>Refresh</PrimaryButton>
                        <PrimaryButton variant="contained" className="clearFiltersButton" onClick={this.handleClear}>Clear</PrimaryButton>
                      </div>
                      <TextField
                        className="filterInputContainer"
                        value={filterText === null ? "" : filterText}
                        onChange={this.handleFilterTextChange}
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><label htmlFor="filterInput"><FilterListIcon
                            title="Filter the questions listing"/></label></InputAdornment>,
                          id: "filterInput",
                          title: "Filter the questions listing",
                          'aria-label': "Filter the questions listing"
                        }}
                      />
                    </div>
                    <div className="filterLabel">
                      Displaying {(displayedQuestionIds === null ? Object.keys(questions).length : displayedQuestionIds.length)} of {Object.keys(questions).length} questions
                    </div>
                  </div>
                </div>
              </Sticky>
            </If>
          </PageControlContent>
        </PageControl>
      </Fragment>
    );
  }
}
export default QuestionFilters;