import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import {ParkingUseTypes} from "./ParkingUses";

const ParkingUseCategorySelection = (props) => {
  return (
    <Select
      value={props.currentValue}
      onChange={props.onChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      {Object.keys(ParkingUseTypes).map((category) => (
        <MenuItem key={category} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  );
};
export default ParkingUseCategorySelection;