import React from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

const SignageResultTableBody = ({result}) => {
  return(
    <TableBody>
      {result.specs && Object.keys(result.specs).map((spec) => {
        let resvalue = result.specs[spec]['value'];
        if (typeof resvalue === 'number') {
          if (!Number.isInteger(resvalue)) {
            resvalue = resvalue.toFixed(2);
          }
        }
        return (<TableRow key={spec}>
          <TableCell key="aspect">
            <h4>{spec}</h4>
          </TableCell>
          <TableCell key="value">
            {spec.includes('Illustrated') &&
              <img alt={result.signType} src={result.specs[spec]['value']}></img>}
            {spec.includes('Notes') &&
              <div dangerouslySetInnerHTML={{__html:result.specs[spec]['value']}}/>}
            {!spec.includes('Illustrated') && !spec.includes('Notes') && <h4>{resvalue} {result.specs[spec]['label']}</h4>}
          </TableCell>
        </TableRow>);
      })}
    </TableBody>

  );
};
export default SignageResultTableBody;