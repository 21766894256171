import React, {useCallback, useEffect, useState} from 'react';
import {Table, TableCell, TableHead, TableRow} from "../../../common/components";
import {Button} from "@material-ui/core";
import {isNotBlank} from "../../../common/utils";
import {PermanentSignageUseTypes, TemporarySignageUseTypes} from "./SignageTypes";
import SignageResultTableBody from "./SignageResultTableBody";

const SignageResults = ({signageClass, signageDistrict, signType, formData, formDataComplete, pinned, pin, unpin}) => {
  const [result, setResult] = useState({});
  const [descriptor, setDescriptor] = useState((signageClass === 'Permanent') ? PermanentSignageUseTypes[signageDistrict][signType] : TemporarySignageUseTypes[signageDistrict][signType]);

  const pinResult =  (result) => {
    pin(result);
    setResult({});
  };

  const unpinResult = (key) => {
    unpin(key);
  };

  const buildKey = useCallback(() => {
    let key = signageDistrict + " | " + signType;
    if (descriptor) {
      if (descriptor.form) {
        descriptor.form.forEach((form) => {
          if ((form.req === undefined || formData[form.req]) && formData[form.value_designator]) {
            let fragment = " | " + ((typeof formData[form.value_designator] !== 'boolean') ? formData[form.value_designator] : '') + " " + form.label;
            key = key + fragment;
          }
        });
      }
    }
    return key;
  }, [descriptor, formData, signType, signageDistrict]);

  const isAlreadyPinned = useCallback(() => {
    let rc = false;
    if (pinned && pinned.length > 0) {
      for (const pin in pinned) {
        if (pinned[pin].key === buildKey(formData)) {
          rc = true;
          break;
        }
      }
    }
    return rc;
  }, [buildKey, formData, pinned]);

  useEffect(() => {
    if (descriptor) {
      let result = {};
      result.key = buildKey();
      result.signType = signType;
      result.specs = {};
      Object.keys(descriptor.specs).map((spec) => {
        if (descriptor.specs[spec] !== null) {
          let specDescriptor = descriptor.specs[spec];
          if (specDescriptor.req !== undefined && !formData[specDescriptor.req]) {
            return null;
          }
          let rc = {};
          if (typeof specDescriptor === "boolean") {
            rc.value = specDescriptor ? 'Yes' : 'No';
          } else {
            if (specDescriptor.hasOwnProperty('thresholds')) {
              let total = 0;
              for (let i = 0; i < specDescriptor.thresholds.length; i++) {
                let quantity = specDescriptor.thresholds[i];
                let newVal = (quantity.link_designator) ? result.specs[quantity.link_designator]['value'] : formData[quantity.value_designator];
                if (newVal <= quantity.max || quantity.max === 0) {
                  let baseline = (quantity.baseline) ? quantity.baseline : 0;
                  newVal = baseline + quantity.base * ((quantity.quanta) ? (newVal / quantity.quanta) : 1);
                  total = total + newVal;
                  break;
                }
              }
              if (specDescriptor.max_result && total > specDescriptor.max_result) {
                total = specDescriptor.max_result;
              }
              if (specDescriptor.min_result && total < specDescriptor.min_result) {
                total = specDescriptor.min_result;
              }
              rc.value = total;
            }
            if (specDescriptor.hasOwnProperty('fixed')) {
              rc.label = specDescriptor.fixed;
            }
          }
          if (spec.includes('Illustrated') || spec.includes('Notes')) {
            rc.value = specDescriptor;
          }
          result.specs[spec] = rc;
        }
        return null;
      });
      setResult(result);
    }
  },[buildKey, descriptor, formData, signType]);

  useEffect(() => {
    if (isNotBlank(signageDistrict) && isNotBlank(signType)) {
      setDescriptor((signageClass === 'Permanent') ? PermanentSignageUseTypes[signageDistrict][signType] : TemporarySignageUseTypes[signageDistrict][signType]);
    } else {
      setDescriptor(null);
    }
  }, [signageClass, signageDistrict, signType]);

  return (
    <div className="calculatorResult">
      <h2>Results</h2>
      {descriptor && !isAlreadyPinned() && formDataComplete && <div className="signageSpaceResults">
        <Table id="signageResultDetail" className="signageResultTable reactTable">
          <colgroup>
            <col key="aspect" style={{width: '40%'}}/>
            <col key="value" style={{width: '60%'}}/>
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell key="aspect">
                <h3>{result.key}</h3>
              </TableCell>
              <TableCell key="value">
                <Button variant={'contained'} onClick={() => pinResult(result)}>Pin</Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <SignageResultTableBody result={result}/>
        </Table>
      </div>}
      {pinned && pinned.length > 0 && <div className='signagePinnedResultsContainer'>
        {pinned.map((result) => {
          return (
            <div key={result.key} className="signagePinnedResultsTablesContainer">
              <Table id="signageResultDetail" className="signageResultTable reactTable">
                <colgroup>
                  <col key="aspect" style={{width: '40%'}}/>
                  <col key="value" style={{width: '60%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell key="aspect">
                      <h3>{result.key}</h3>
                    </TableCell>
                    <TableCell key="value">
                      <Button variant={'contained'} onClick={() => unpinResult(result.key)}>Unpin</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <SignageResultTableBody result={result} />
              </Table>
            </div>
          );
        })}
      </div>
      }
    </div>
  );
};
export default SignageResults;


