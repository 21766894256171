import React, {Fragment} from "react";
import {TocPageControl} from "../../common/components";
import * as BufferyardTypes from "./bufferyard/BufferyardTypes";
import * as ParkingTypes from "./parking/ParkingTypes";
import * as SignageClasses from "./signage/SignageClasses";

const CalculatorPageControls = (props) => {
  const BUFFERYARD = 'Bufferyard';
  const PARKING = 'Parking';
  const SIGNAGE = 'Signage';
  const calculatorTypes = [BUFFERYARD, PARKING, SIGNAGE];  const formToc = () => {
    const {custId} = props;
    let tocNodes = [];
    for (const calculatorType of calculatorTypes) {
      let tocNode = {
        id: `calculator-type-${calculatorType}`,
        label: calculatorType,
        href: `/calculators/${custId}/${calculatorType.toLowerCase()}`,
        pageHeadingId: "pageHeadingId",
        childNodes: []
      };
      let calculatorSubTypes = {};
      let currentType = "";
      if (calculatorType === BUFFERYARD) {
        let bufferyardTypeMap = new Map();
        for (const pType in BufferyardTypes) {
          bufferyardTypeMap.set(pType, BufferyardTypes[pType]);
        }
        calculatorSubTypes = Object.fromEntries(bufferyardTypeMap);
        currentType = props.bufferyardType ? props.bufferyardType.toLowerCase() : "";
      } else if (calculatorType === PARKING) {
        let parkingTypeMap = new Map();
        for (const pType in ParkingTypes) {
          parkingTypeMap.set(pType, ParkingTypes[pType]);
        }
        calculatorSubTypes = {};
      } else {
        let signageTypeMap = new Map();
        for (const pType in SignageClasses) {
          signageTypeMap.set(pType, SignageClasses[pType]);
        }
        calculatorSubTypes = Object.fromEntries(signageTypeMap);
        currentType = props.signageClass ? props.signageClass.toLowerCase() : "";
      }
      for (const subType of Object.keys(calculatorSubTypes)) {
        let href = `/calculators/${custId}/${calculatorType.toLowerCase()}` + ((calculatorType === PARKING) ? `` : `?${calculatorType.toLowerCase()}`) + ((calculatorType === SIGNAGE) ? 'Class' : 'Type') + `=${subType.toLowerCase()}`;
        let subNode = {
          id: `calculator-type-${calculatorType}-${subType}`,
          label: calculatorSubTypes[subType],
          selected: subType.toLowerCase() === currentType.toLowerCase(),
          href: `${href}`,
          pageHeadingId: "pageHeadingId",
        };
        tocNode.childNodes.push(subNode);
      }
      tocNodes.push(tocNode);
    }
    return tocNodes;
  };

  return(
    <Fragment>
      <TocPageControl type="calculators" tocNodes={formToc()}/>
    </Fragment>
  );

};
export default CalculatorPageControls;
CalculatorPageControls.propTypes = {
};