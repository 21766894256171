import React from 'react';
import {TextField} from "../../../common/components";
import FormControl from "@material-ui/core/FormControl";

const ParkingUseTypeForm = ({formData, parkingUseForm, handleChange}) => {

  const handleFormChange = (e) => {
    handleChange(e.target.name, e.target.value);
  };

  return (
    <div className='parkingFormControlContainer'>
      {(parkingUseForm && parkingUseForm.length > 0) && <div className='parkingFormInputContainer'>
        {parkingUseForm.map((form) => {
          return (
            <div key={form.value_designator}>
              {form.value_designator === 'tfa' && <h5>Loading Spaces are Required</h5>}
              <FormControl
                fullWidth={true}>
                <TextField
                  id={form.value_designator}
                  name={form.value_designator}
                  label={form.label}
                  type={form.type}
                  value={(formData[form.value_designator]) ? formData[form.value_designator] : ""}
                  onChange={handleFormChange}
                  placeholder={form.label}
                  inputProps={{ min: 0}}
                />
              </FormControl>
            </div>
          );
        })}
      </div>}
    </div>
  );
};
export default ParkingUseTypeForm;