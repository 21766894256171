import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BufferyardDistrictSelection from "./BufferyardDistrictSelection";
import {TextField} from "../../../common/components";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";
import {DISTRICT} from "./BufferyardTypes";

const BufferyardDistrictForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <FormControl
        fullWidth={true}>
        <InputLabel htmlFor="developmentDistrict">Zoning of Parcel Proposed for Development</InputLabel>
        <BufferyardDistrictSelection bufferyardType={DISTRICT} currentValue={props.developmentDistrict} onChange={props.handleChange}
          listName="developmentDistrict"/>
      </FormControl>
      <FormControl
        fullWidth={true}>
        <InputLabel htmlFor="adjoiningDistrict">Zoning of Adjoining District</InputLabel>
        <BufferyardDistrictSelection bufferyardType={DISTRICT}  currentValue={props.adjoiningDistrict} onChange={props.handleChange}
          listName="adjoiningDistrict"/>
      </FormControl>
      <FormControl
        fullWidth={true}>
        <TextField
          id="propertyLength"
          name="propertyLength"
          label="Length of Property Line (linear feet)"
          type="number"
          value={props.propertyLength}
          onChange={props.handleChange}
          placeholder="Linear Feet"
          inputProps={{ min: 0}}
        />
      </FormControl>
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardDistrictForm;
BufferyardDistrictForm.propTypes = {
  adjoiningDistrict: PropTypes.string,
  clearForm: PropTypes.func,
  developmentDistrict: PropTypes.string,
  propertyLength: PropTypes.number,
  handleChange: PropTypes.func,
};