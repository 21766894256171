import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";

const BufferyardStreetSelection = (props) => {
  let streetClassMap = {
    "Arterial":"Arterial Street",
    "Collector": "Collector Street",
    "Local": "Local Street"
  };

  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <Select
      value={props.currentValue}
      onChange={handleChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      {Object.keys(streetClassMap).map((streetClass) => (
        <MenuItem key={streetClass} value={streetClass}>
          {streetClassMap[streetClass]}
        </MenuItem>
      ))}
    </Select>
  );
};
export default BufferyardStreetSelection;
BufferyardStreetSelection.propTypes = {
  currentValue: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};
