import React from "react";
import {ReactComponent as LawIcon} from "./assets/new-law.svg";
import "./icons.css";

const IcoMoonFontIcon =
  ({
    iconName,
    alt = "icon",
    title = "",
    className = "",
    size = 24,
    color = null,
    ...otherProps
  }) =>
    <span
      className={`font-icon md-${size} notranslate ${className}`}
      translate="no"
      aria-label={alt}
      style={{color: color}}
      {...otherProps}
    >
      {iconName}
    </span>;

const MaterialFontIcon =
  ({
    iconName,
    alt = "icon",
    title = "",
    className = "",
    size = 24,
    color = null,
    filled = true,
    ...otherProps
  }) =>
    <span
      className={`material-icons md-${size} notranslate material-symbols-outlined ${className} ${filled ? 'material-symbols-and-filled' : ''}`}
      translate="no"
      aria-label={alt}
      style={{color: color}}
      {...otherProps}
    >
      {iconName}
    </span>;

const AccountCircle = ({alt = "Account icon", ...props}) =>
  MaterialFontIcon({
    iconName: "account_circle",
    color: '#aaa',
    alt: alt,
    ...props
  });
const Add = ({alt = "Add icon", ...props}) =>
  MaterialFontIcon({
    iconName: "add_circle",
    alt: alt,
    ...props
  });
const AddAlert = ({alt = "Add Alert icon", ...props}) => MaterialFontIcon({iconName: "add_alert", alt, ...props});
const Admin = ({alt = "Admin icon", title = "Admin", ...props}) =>
  MaterialFontIcon({
    iconName: "build",
    alt: alt,
    title: title,
    ...props
  });
const ArrowBack = ({alt = "Back arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "arrow_back",
    alt: alt,
    ...props
  });
const ArrowDropDown = ({alt = "Dropdown arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "arrow_drop_down",
    alt: alt,
    ...props
  });
const ArrowDownward = ({alt = "Upward arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "arrow_upward",
    alt: alt,
    ...props
  });
const ArrowForward = ({alt = "Forward arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "arrow_forward",
    alt: alt,
    ...props
  });
const ArrowUpward = ({alt = "Downward arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "arrow_downward",
    alt: alt,
    ...props
  });
const AudioFile = ({alt = "Dropdown arrow icon", ...props}) =>
  MaterialFontIcon({
    iconName: "audio_file",
    alt: alt,
    ...props
  });
const Calculate = ({alt = "Calculator icon", ...props}) =>
  MaterialFontIcon({
    iconName: "calculate",
    alt: alt,
    ...props
  });
const Checkmark = ({alt = "Checkmark icon", title = "Complete", ...props}) =>
  MaterialFontIcon({
    iconName: "checkmark",
    alt: alt,
    title: title,
    ...props
  });
const CheckmarkCircle =
  ({
    alt = "Checkmark circle icon",
    title = "Complete",
    ...props
  }) =>
    MaterialFontIcon({
      iconName: "check_circle",
      alt: alt,
      title: title,
      ...props
    });
const Circle = ({alt = "Circle icon", title = "Incomplete", ...props}) =>
  MaterialFontIcon({
    iconName: "circle",
    alt: alt,
    title: title,
    filled: false,
    ...props
  });
const CircleX = ({alt = "X circle icon", title = "Close", ...props}) =>
  MaterialFontIcon({
    iconName: "cancel",
    alt: alt,
    title: title,
    ...props
  });
const Clear = ({alt = "X icon", title = "Clear", ...props}) =>
  MaterialFontIcon({
    iconName: "close",
    alt: alt,
    title: title,
    ...props
  });
const Code = ({alt = "Code icon", title = "Code", ...props}) =>
  IcoMoonFontIcon({
    iconName: "code",
    alt: alt,
    title: title,
    ...props
  });
const Collapse = ({alt = "Collapse icon", title = "Collapse", ...props}) =>
  MaterialFontIcon({
    iconName: "remove",
    alt: alt,
    title: title,
    ...props
  });
const Copy = ({alt = "Copy icon", title = "Copy", ...props}) =>
  MaterialFontIcon({
    iconName: "file_copy",
    alt: alt,
    title: title,
    ...props
  });
const Delete = ({alt = "Delete icon", title = "Delete", ...props}) =>
  MaterialFontIcon({
    iconName: "delete",
    alt: alt,
    title: title,
    ...props
  });
const DocumentFile = ({alt = "Document file icon", ...props}) =>
  MaterialFontIcon({
    iconName: "description",
    alt: alt,
    ...props
  });
const Download = ({alt = "Download icon", ...props}) =>
  MaterialFontIcon({
    iconName: "download",
    alt: alt,
    ...props
  });
const ECode = ({alt = "eCode360 icon", title = "eCode360", ...props}) =>
  IcoMoonFontIcon({
    iconName: "ecode",
    alt: alt,
    title: title,
    ...props
  });
const Edit = ({alt = "Edit icon", title = "Edit", ...props}) =>
  MaterialFontIcon({
    iconName: "edit",
    alt: alt,
    title: title,
    ...props
  });
const Email = ({alt = "Email icon", title = "Email", ...props}) =>
  MaterialFontIcon({
    iconName: "email",
    alt: alt,
    title: title,
    ...props
  });
const EmailAlt = ({alt = "Email icon", title = "Email", ...props}) =>
  MaterialFontIcon({
    iconName: "alternate_email",
    alt: alt,
    title: title,
    ...props
  });
const Error = ({alt = "Error icon", title = "Error", ...props}) =>
  MaterialFontIcon({
    iconName: "report",
    alt: alt,
    title: title,
    ...props
  });
const Expand = ({alt = "Expand icon", title = "Expand", ...props}) =>
  MaterialFontIcon({
    iconName: "add",
    alt: alt,
    title: title,
    ...props
  });
const ExpandMore = ({alt = "Expand icon", title = "Expand", ...props}) =>
  MaterialFontIcon({
    iconName: "expand_more",
    alt: alt,
    title: title,
    ...props
  });
const FilterList = ({alt = "Filter icon", title = "Filter", ...props}) =>
  MaterialFontIcon({
    iconName: "filter_list",
    alt: alt,
    title: title,
    ...props
  });
const Help = ({alt = "Help icon", className = "helpIcon", ...props}) =>
  MaterialFontIcon({
    iconName: "help_center",
    alt: alt,
    className: className,
    ...props
  });
const Home = ({alt = "Home icon", title = "Home", ...props}) =>
  MaterialFontIcon({
    iconName: "home",
    alt: alt,
    title: title,
    ...props
  });
const Key = ({alt = "Key icon", ...props}) =>
  MaterialFontIcon({
    iconName: "key",
    alt: alt,
    ...props
  });
const Link = ({alt = "Link icon", ...props}) =>
  MaterialFontIcon({
    iconName: "link",
    alt: alt,
    ...props
  });
const MenuOptionsHoriz = ({alt = "Menu options icon", ...props}) =>
  MaterialFontIcon({
    iconName: "more_horiz",
    alt: alt,
    ...props
  });
const MenuOptionsVert = ({alt = "Menu options icon", ...props}) =>
  MaterialFontIcon({
    iconName: "more_vert",
    alt: alt,
    ...props
  });
const NewLawNew = ({alt = "New law icon", title = "New Law", ...props}) =>
  MaterialFontIcon({
    iconName: "gavel",
    alt: alt,
    title: title,
    ...props
  });
const NewLawOld =
  ({
    alt = "New law icon",
    title = "New Law",
    className = "",
    ...props
  }) =>
    <LawIcon
      alt={alt}
      title={title}
      className={"medium svgIcon " + className}
      {...props}
    />;
const Note = ({alt = "Note icon", ...props}) =>
  IcoMoonFontIcon({
    iconName: "note",
    alt: alt,
    ...props
  });
const NoteClose = ({alt = "Collapse note icon", ...props}) =>
  IcoMoonFontIcon({
    iconName: "note_collapse",
    alt: alt,
    ...props
  });
const NoteOpen = ({alt = "Expand note icon", ...props}) =>
  IcoMoonFontIcon({
    iconName: "note_expand",
    alt: alt,
    ...props
  });
const Pdf = ({alt = "Pdf file", className = "", ...props}) =>
  IcoMoonFontIcon({
    iconName: "pdf",
    alt: alt,
    className: "pdfIcon faded " + className,
    ...props
  });
const Person = ({alt = "Person icon", title = "Person", ...props}) =>
  MaterialFontIcon({
    iconName: "person",
    alt: alt,
    title: title,
    ...props

  });
const Phone = ({alt = "Phone icon", title = "Phone Number", ...props}) =>
  MaterialFontIcon({
    iconName: "phone",
    alt: alt,
    title: title,
    ...props
  });
const PinDrop = ({alt = "Map pin icon", ...props}) =>
  MaterialFontIcon({
    iconName: "pin_drop",
    alt: alt,
    ...props
  });
const Play = ({alt = "Play icon", title = "Play Video", ...props}) =>
  MaterialFontIcon({
    iconName: "play_circle",
    alt: alt,
    title: title,
    ...props
  });
const Print = ({alt = "Print icon", title = "Print", ...props}) =>
  MaterialFontIcon({
    iconName: "print",
    alt: alt,
    title: title,
    ...props
  });
const PubDoc = ({alt = "Public document icon", ...props}) =>
  MaterialFontIcon({
    iconName: "inventory_2",
    alt: alt,
    ...props
  });
const Questions = ({alt = "Questions icon", title = "Questions", ...props}) =>
  MaterialFontIcon({
    iconName: "forum",
    alt: alt,
    title: title,
    ...props
  });
const Remove = ({alt = "Remove icon", title = "Remove", ...props}) =>
  MaterialFontIcon({
    iconName: "remove_circle",
    alt: alt,
    title: title,
    ...props
  });
const Save = ({alt = "Save icon", title = "Save", ...props}) =>
  MaterialFontIcon({
    iconName: "save",
    alt: alt,
    title: title,
    ...props
  });
const Search = ({alt = "Search icon", title = "Search", ...props}) =>
  MaterialFontIcon({
    iconName: "search",
    alt: alt,
    title: title,
    ...props
  });
const Share = ({alt = "Share icon", title = "Share", ...props}) =>
  MaterialFontIcon({
    iconName: "share",
    alt: alt,
    title: title,
    ...props
  });
const TopPanelOpen = ({alt = "Open page controls", title = "Open", ...props}) =>
  MaterialFontIcon({
    iconName: 'top_panel_open',
    alt: alt,
    title: title,
    ...props
  });
const VideoFile = ({alt = "Video file icon", ...props}) =>
  MaterialFontIcon({
    iconName: "video_library",
    alt: alt,
    ...props
  });
const VisibilityOff = ({alt = "No visible icon", ...props}) =>
  MaterialFontIcon({
    iconName: "visibility_off",
    alt: alt,
    ...props
  });
const Warning = ({alt = "Warning icon", title = "Warning", ...props}) =>
  MaterialFontIcon({
    iconName: "warning",
    alt: alt,
    title: title,
    ...props
  });
const Zoning = ({alt = "Zoning icon", title = "Zoning", ...props}) =>
  MaterialFontIcon({
    iconName: "map",
    alt: alt,
    title: title,
    ...props
  });

export {
  AccountCircle,
  Add,
  AddAlert,
  Admin,
  ArrowDropDown,
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  ArrowUpward,
  AudioFile,
  Calculate,
  Checkmark,
  CheckmarkCircle,
  Circle,
  CircleX,
  Clear,
  Code,
  Collapse,
  Copy,
  Delete,
  DocumentFile,
  Download,
  ECode,
  Edit,
  Email,
  EmailAlt,
  Error,
  Expand,
  ExpandMore,
  FilterList,
  Help,
  Home,
  Key,
  Link,
  MenuOptionsHoriz,
  MenuOptionsVert,
  NewLawNew,
  NewLawOld,
  Note,
  NoteClose,
  NoteOpen,
  Pdf,
  Person,
  Phone,
  PinDrop,
  Play,
  Print,
  PubDoc,
  Questions,
  Remove,
  Save,
  Search,
  Share,
  TopPanelOpen,
  VideoFile,
  VisibilityOff,
  Warning,
  Zoning
};