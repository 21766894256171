import React from 'react';
import {ListSubheader, MenuItem, Select} from "../../../common/components";
import PropTypes from "prop-types";
import {STREET} from "./BufferyardTypes";

const BufferyardDistrictSelection = (props) => {
  let baseResidentialDistrictMap = {
    "RE":"Residential Estates",
    "SF-1": "Very Low Density Single Family",
    "SF-2": "Low Density Single Family",
    "MDR": "Medium Density Residential",
    "HDR": "High Density Residential"
  };
  let baseMixedUseDistrictMap = {
    "MU-1": "West Broadway",
    "MU-2": "Broadway",
    "MU-3": "General",
    "MU-5": "Civic Center"
  };
  let basePublicNonResidentialDistrictMap = {
    "NC": "Neighborhood Commercial",
    "AC": "Auto-Urban Commercial",
    "OF": "Office",
    "HC": "Heavy Commercial",
    "IP": "Industrial Park",
    "LI": "Light Industrial",
    "GI": "General Industrial"
  };
  let streetClassDistrictMap = {
    "AC": "Auto-Urban Commercial",
    "OF": "Office",
    "RE":"Residential Estates (Cluster Subdivision Type)",
    "SF-1": "Very Low Density Single Family (Cluster Subdivision Type)",
    "SF-2": "Low Density Single Family (Cluster Subdivision Type)",
    "Oth": "Other"
  };

  return (
    (props.bufferyardType !== STREET) ? <Select
      value={props.currentValue}
      onChange={props.onChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      <ListSubheader>-- Base Residential Districts --</ListSubheader>
      {Object.keys(baseResidentialDistrictMap).map((district) => (
        <MenuItem key={district} value={district}>
          {baseResidentialDistrictMap[district]} ({district})
        </MenuItem>
      ))}
      <ListSubheader>--  Base Mixed-Use Districts --</ListSubheader>
      {Object.keys(baseMixedUseDistrictMap).map((district) => (
        <MenuItem key={district} value={district}>
          {baseMixedUseDistrictMap[district]} ({district})
        </MenuItem>
      ))}
      <ListSubheader>-- Base Public and Nonresidential Districts --</ListSubheader>
      {Object.keys(basePublicNonResidentialDistrictMap).map((district) => (
        <MenuItem key={district} value={district}>
          {basePublicNonResidentialDistrictMap[district]} ({district})
        </MenuItem>
      ))}
    </Select> :
      <Select
        value={props.currentValue}
        onChange={props.onChange}
        inputProps={{
          name: props.listName,
          id: props.listName,
        }}
      >
        <ListSubheader>Zoning District</ListSubheader>
        {Object.keys(streetClassDistrictMap).map((district) => (
          <MenuItem key={district} value={district}>
            {streetClassDistrictMap[district]} ({district})
          </MenuItem>
        ))}
      </Select>);
};
export default BufferyardDistrictSelection;
BufferyardDistrictSelection.propTypes = {
  bufferyardType: PropTypes.string,
  currentValue: PropTypes.string,
  listName: PropTypes.string,
  onChange: PropTypes.func,
};
