import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "../../../common/components";
import {Button} from "@material-ui/core";
import PropTypes from "prop-types";

const BufferyardParkingForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <FormControl
        fullWidth={true}>
        <TextField
          id="parkingSpaces"
          name="parkingSpaces"
          label="Number of Parking Spaces"
          type="number"
          value={props.parkingSpaces}
          onChange={props.handleChange}
          placeholder="Number of Parking Spaces"
          inputProps={{ min: 0}}
        />
      </FormControl>
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardParkingForm;
BufferyardParkingForm.propTypes = {
  clearForm: PropTypes.func,
  parkingSpaces: PropTypes.number,
  handleChange: PropTypes.func,
};