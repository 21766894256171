import {OTHER as OTHER_PAGE} from "./page-types";
import {OTHER as OTHER_SUB_PAGE} from "./page-sub-types";

const archiveId = ((window && window.SERVER_DATA && window.SERVER_DATA.archiveId) || null);
const contentPreviewDelay = ((window && window.SERVER_DATA && window.SERVER_DATA.contentPreviewDelay) || 0);
const contentPreviewSelector = ((window && window.SERVER_DATA && window.SERVER_DATA.contentPreviewSelector) || null);
const custId = ((window && window.SERVER_DATA && window.SERVER_DATA.custId) || null);
const customer = ((window && window.SERVER_DATA && window.SERVER_DATA.customer) || null);
const customerName = ((window && window.SERVER_DATA && window.SERVER_DATA.customerName) || null);
const customerWarnings = ((window && window.SERVER_DATA && window.SERVER_DATA.customerWarnings) || null);
const globalPublicMessage = ((window && window.SERVER_DATA && window.SERVER_DATA.globalPublicMessage) || "");
const globalPublicMessageDismissalDays = ((window && window.SERVER_DATA && window.SERVER_DATA.globalPublicMessageDismissalDays) || 365);
const hasDashboardAccess = ((window && window.SERVER_DATA && window.SERVER_DATA.hasDashboardAccess) || false);
const lawCount = ((window && window.SERVER_DATA && window.SERVER_DATA.lawCount) || 0);
const lawLedgerCount = ((window && window.SERVER_DATA) ? window.SERVER_DATA.lawLedgerCount : null);
const maxSearchPrintPages = ((window && window.SERVER_DATA && window.SERVER_DATA.maxSearchPrintPages) || null);
const myNotesCount = ((window && window.SERVER_DATA && window.SERVER_DATA.myNotesCount) || 0);
const newNav = ((window && window.SERVER_DATA && window.SERVER_DATA.newNav) || false);
const hasCalculator_Bufferyards = ((window.SERVER_DATA?.calculators?.bufferyards) || false);
const hasCalculator_Parking = ((window.SERVER_DATA?.calculators?.parking) || false);
const hasCalculator_Signage = ((window.SERVER_DATA?.calculators?.signage) || false);
const hasDistributionData = ((window && window.SERVER_DATA && window.SERVER_DATA.hasDistributionData) || false);
const isIndexViewVisible = ((window && window.SERVER_DATA && window.SERVER_DATA.indexViewVisible) || false);
const isAdminOptionHide =  ((window && window.SERVER_DATA && window.SERVER_DATA.adminOptionHide) || false);
const noteCount = ((window && window.SERVER_DATA && window.SERVER_DATA.noteCount) || 0);
const noteLocationData = ((window && window.SERVER_DATA && window.SERVER_DATA.noteLocationData) || null);
const pageSubType = ((window && window.SERVER_DATA && window.SERVER_DATA.pageSubType) || OTHER_SUB_PAGE);
const pageType = ((window && window.SERVER_DATA && window.SERVER_DATA.pageType) || OTHER_PAGE);
const pubDocsOnly = ((window && window.SERVER_DATA && window.SERVER_DATA.pubDocsOnly) || false);
const searchResultsPerPage = ((window && window.SERVER_DATA && window.SERVER_DATA.searchResultsPerPage) || null);
const searchResultsUrl = ((window && window.SERVER_DATA && window.SERVER_DATA.searchResultsUrl) || null);
const textQuickLinks = ((window && window.SERVER_DATA && window.SERVER_DATA.textQuickLinks) || null);
const contentGraphicalQuickLinks = ((window && window.SERVER_DATA && window.SERVER_DATA.contentGraphicalQuickLinks) || null);
const username = ((window && window.SERVER_DATA && window.SERVER_DATA.username) || null);
const versions = ((window && window.SERVER_DATA && window.SERVER_DATA.versions) || null);
const zoningGuid = ((window && window.SERVER_DATA && window.SERVER_DATA.zoningGuid) || null);

export {
  archiveId,
  contentGraphicalQuickLinks,
  contentPreviewDelay,
  contentPreviewSelector,
  custId,
  customer,
  customerName,
  customerWarnings,
  globalPublicMessage,
  globalPublicMessageDismissalDays,
  hasDashboardAccess,
  lawCount,
  maxSearchPrintPages,
  myNotesCount,
  newNav,
  noteCount,
  noteLocationData,
  pageSubType,
  pageType,
  lawLedgerCount,
  pubDocsOnly,
  searchResultsPerPage,
  searchResultsUrl,
  textQuickLinks,
  username,
  versions,
  zoningGuid,
  hasCalculator_Bufferyards,
  hasCalculator_Parking,
  hasCalculator_Signage,
  hasDistributionData,
  isIndexViewVisible,
  isAdminOptionHide
};