import React, {useEffect, useState} from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {isBlank, isNotBlank} from "../../../common/utils";
import ParkingUseCategorySelection from "./ParkingUseCategorySelection";
import ParkingUseTypeSelection from "./ParkingUseTypeSelection";
import {Button} from "@material-ui/core";
import {ParkingUseTypes} from "./ParkingUses";
import ParkingUseTypeForm from "./ParkingUseTypeForm";
import ParkingResults from "./ParkingResults";
import {Checkbox, FormControlLabel} from "../../../common/components";
import {cloneDeep} from "lodash";

const haveFormData = (parkingUseCategory, parkingUseType, formData) => {
  let rc = true;
  if (isBlank(parkingUseCategory) || isBlank(parkingUseType)) {
    return false;
  }
  let descriptor = ParkingUseTypes[parkingUseCategory][parkingUseType];
  if ((descriptor.form && descriptor.form.length > 0) && Object.keys(formData).length <= 0) {
    return false;
  }
  if (descriptor.loading_required) {
    if (isBlank(formData['tfa'])) {
      return false;
    }
  }
  Object.keys(formData).forEach((key) => {
    if (isBlank(formData[key])) {
      rc = false;
    }
  });
  return rc;
};

const ParkingCalculator = () => {
  const [parkingUseCategory, setParkingUseCategory] = useState("");
  const [parkingUseType, setParkingUseType] = useState("");
  const [formData, setFormData] = useState({});
  const [parkingUseForm, setParkingUseForm] = useState(null);
  const [infill, setInfill] = useState(false);
  const [complies, setComplies] = useState(false);
  const [pinned, setPinned] = useState([]);

  const handleParkingUseCatagoryChange = (e) => {
    setParkingUseCategory(e.target.value);
    setParkingUseType("");
    setFormData({});
  };

  const handleParkingUseTypeChange = (e) => {
    setParkingUseType(e.target.value);
    setFormData({});
  };

  const handleInfillChange = (e) => {
    setInfill(e.target.checked);
    setComplies(false);
  };

  const handleCompliesChange = (e) => {
    setComplies(e.target.checked);
  };

  const clearForm = () => {
    setFormData({});
    setParkingUseType("");
    setParkingUseCategory("");
  };

  const pinResult =  (result) => {
    let newPinned = pinned.concat();
    newPinned.unshift(result);
    setPinned(newPinned);
    setFormData({});
  };

  const unpinResult = (key) => {
    let newPinned = pinned.filter((item) => item.key !== key);
    setPinned(newPinned);
  };

  const handleFormDataChange = (key, value) => {
    setFormData({...formData, [key]: value});
  };

  useEffect(() => {
    if (isNotBlank(parkingUseCategory) && isNotBlank(parkingUseType)) {
      const parkingTypeDescriptor = ParkingUseTypes[parkingUseCategory][parkingUseType];
      if (parkingTypeDescriptor.form) {
        const parkingUseForm = cloneDeep(parkingTypeDescriptor.form);
        if (parkingTypeDescriptor.loading_required) {
          let p = parkingUseForm.filter((quantity) => quantity.value_designator === 'tfa');
          if (p.length <= 0) {
            parkingUseForm.push({value_designator: 'tfa', label: 'Total Floor Area Sq. Ft.', type: 'number'});
          }
        }
        setParkingUseForm(parkingUseForm);
      } else {
        setParkingUseForm(null);
      }
    } else {
      setParkingUseForm(null);
    }
  }, [parkingUseCategory, parkingUseType]);

  return (
    <div className="calculator">
      <div className="calculatorForm">
        <h2>Required Information</h2>
        <FormControl
          fullWidth={true}>
          <InputLabel htmlFor="parkingUseCategory">Use Category</InputLabel>
          <ParkingUseCategorySelection currentValue={parkingUseCategory} onChange={handleParkingUseCatagoryChange}
            listName='parkingUseCategory'/>
        </FormControl>
        {isNotBlank(parkingUseCategory) &&
          <FormControl
            fullWidth={true}>
            <InputLabel htmlFor="parkingUseType">Use Type</InputLabel>
            <ParkingUseTypeSelection currentValue={parkingUseType} onChange={handleParkingUseTypeChange}
              parkingUseCategory={parkingUseCategory} listName='parkingUseType'/>
          </FormControl>
        }
        {parkingUseForm &&
          <ParkingUseTypeForm formData={formData} handleChange={handleFormDataChange} parkingUseForm={parkingUseForm}/>}
        {parkingUseForm && <FormControlLabel
          key={"infill"}
          className="filterLabel"
          control={
            <Checkbox
              id={"infill"}
              checked={infill}
              onChange={handleInfillChange}
              style={{height: 'auto'}}
            />
          }
          label={"This use is located in a Neighborhood Infill Overlay or Community Infill Overlay district."}
        />}
        {parkingUseForm && infill && <div><FormControlLabel
          key={"complies"}
          className="filterLabel"
          control={
            <Checkbox
              id={"complies"}
              checked={complies}
              onChange={handleCompliesChange}
              style={{height: 'auto'}}
            />
          }
          label={"This use meets the following Infill Overlay district requirements for reduced parking:"}
        />
        <ul className="compliance-sublist">
          <li>located within 1/4 mile of a Base Residential district and connected by a continuous system of
              sidewalks/pedestrian walkways;
          </li>
          <li>located within 1/4 mile of transit and connected by a continuous system of sidewalks/pedestrian
              walkways; or
          </li>
          <li>abutting a nonresidential use in a Base Mixed-Use district</li>
        </ul>
        </div>}
        <Button variant="contained" className="calcButton" onClick={clearForm}>
          Clear
        </Button>
      </div>
      {((pinned.length > 0) || haveFormData(parkingUseCategory,parkingUseType,formData)) &&
        <ParkingResults useCategory={parkingUseCategory} useType={parkingUseType} formData={formData} infill={infill} compliance={complies} pinned={pinned} pin={pinResult} unpin={unpinResult} />
      }
      <div className="parkingCalculatorNotes">
        <h2>Notes</h2>
        <h3>General Notes</h3>
        <ol>
          <li>
            See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-617"}>Section 39.03.009, Parking Ratios and Design</a>, for allowed shared parking credits and reductions in
            number of required spaces.
          </li>
          <li>
            Uses with 10,000 sq. ft. or less have no minimum required number of loading spaces. See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-619"}>Section 39.03.010,
            Off-Street Loading</a>, for additional requirements.
          </li>
          <li>
            See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-620"}>Subsection 39.03.011, Stacking, for uses requiring stacking spaces</a>.
          </li>
          <li>
            Base Mixed-Use Districts are exempt from these parking and loading requirements; however, if parking is
            provided, the design requirements in <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-617"}>Section 39.03.009, Parking Ratios and Design</a>, apply.
          </li>
          <li>
            Uses in Infill Overlay districts may qualify for a reduction in minimum automobile parking spaces, subject to approval through the Site Development Plan Review process. See <a href={"https://online.encodeplus.com/regs/lubbock-tx/doc-viewer.aspx#secid-617"}>Section 39.03.009.b, Parking in Infill Overlay Districts</a>, for more information.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ParkingCalculator;
export {haveFormData};
ParkingCalculator.propTypes = {};