import React from 'react';
import {MenuItem, Select} from "../../../common/components";
import {ParkingUseTypes} from "./ParkingUses";

const ParkingUseTypeSelection = (props) => {
  return (
    <Select
      value={props.currentValue}
      onChange={props.onChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      {Object.keys(ParkingUseTypes[props.parkingUseCategory]).map((useType) => (
        <MenuItem key={useType} value={useType}>
          {useType}
        </MenuItem>
      ))}
    </Select>
  );
};
export default ParkingUseTypeSelection;