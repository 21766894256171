import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import BufferyardDistrictSelection from "./BufferyardDistrictSelection";
import {TextField} from "../../../common/components";
import {Button} from "@material-ui/core";
import BufferyardStreetSelection from "./BufferyardStreetSelection";
import PropTypes from "prop-types";
import {STREET} from "./BufferyardTypes";
import {isNotBlank} from "../../../common/utils";

const BufferyardStreetForm = (props) => {
  return (
    <div className="bufferyardControlContainer">
      <FormControl
        fullWidth={true}>
        <InputLabel htmlFor="developmentDistrict">Zoning of Parcel Proposed for Development</InputLabel>
        <BufferyardDistrictSelection bufferyardType={STREET} currentValue={props.developmentDistrict} onChange={props.handleChange}
          listName="developmentDistrict"/>
      </FormControl>
      <FormControl
        fullWidth={true}>
        <InputLabel shrink={isNotBlank(props.abuttingStreetClass)} htmlFor="abuttingStreet">Abutting Street Classification</InputLabel>
        <BufferyardStreetSelection currentValue={props.abuttingStreetClass} onChange={props.handleChange}
          listName="abuttingStreet"/>
      </FormControl>
      <FormControl
        fullWidth={true}>
        <TextField
          id="propertyLength"
          name="propertyLength"
          label="Length of Property Line (linear feet)"
          type="number"
          value={props.propertyLength}
          onChange={props.handleChange}
          placeholder="Linear Feet"
          inputProps={{ min: 0}}
        />
      </FormControl>
      <Button variant="contained" className="calcButton" onClick={props.clearForm}>
        Clear
      </Button>
    </div>
  );
};
export default BufferyardStreetForm;
BufferyardStreetForm.propTypes = {
  abuttingStreetClass: PropTypes.string,
  clearForm: PropTypes.func,
  developmentDistrict: PropTypes.string,
  propertyLength: PropTypes.number,
  handleChange: PropTypes.func,
};