/*
 * Copyright 2018 General Code
 */

import {List, OrderedMap, Record} from 'immutable';
import {handleActions} from 'redux-actions';
import * as actions from '../actions';
import {pushState, replaceState} from "../../common/history";


const State = Record({
  laws: OrderedMap(),
  lawLedger: OrderedMap(),
  editingId: null,
  filterText: "",
  filteringText: false,
  filterYear: "",
  filterChapters: [],
  saving: false,
  showDeletedLaws: false,
  sort: []
});

const initialState = State({});

const Person = Record({
  name: null,
  email: null,
});

const Disposition = Record({
  guid: "",
  name: "",
  sortId: 0,
  chapter: null
});

const CodeVersion = Record({
  id: "",
  publishingIdentifier: "",
  importTime: null,
  updatedDate: null
});
const convertDate = (date) => {
  if (!date) return null;
  if (date.getFullYear) return date;
  if (date.date) return new Date(date.date.year, date.date.month, date.date.day, date.time.hour, date.time.minute, date.time.second, date.time.nano);
  return new Date(date);
};
const initCodeVersion = (codeVersionData) => codeVersionData == null ? null
  : CodeVersion({...codeVersionData,
    importTime: convertDate(codeVersionData.importTime),
    updatedDate: convertDate(codeVersionData.updatedDate)
  });

const Law = Record({
  adopted: "",
  adoptedDateAccuracy: "D",
  appendedTo: null,
  comment: "",
  customTitle: "",
  deleted: false,
  description: "",
  dispositions: List(),
  documentId: "",
  legislationType: "",
  legislationYear: "",
  lineNumber: "",
  originalFilename: "",
  rank: null,
  scanDate: "",
  subject: "",
  suppedBy: null,
  title: "",
  updatedBy: Person(),
  updatedOn: null,
  version: null
});

const initLaw = (state, {payload: {lawData}}) => {
  const law = Law({...lawData, dispositions: List(lawData.dispositions.map(d => Disposition({ ...d, chapter: Disposition(d.chapter) }))), suppedBy: initCodeVersion(lawData.suppedBy), appendedTo: initCodeVersion(lawData.appendedTo)});
  return state.setIn(['laws', lawData.documentId], law);
};
const initLawLedger = (state, {payload: {lawData}}) => {
  const law = Law({...lawData, dispositions: List(lawData.dispositions.map(d => Disposition({ ...d, chapter: Disposition(d.chapter) }))), suppedBy: initCodeVersion(lawData.suppedBy), appendedTo: initCodeVersion(lawData.appendedTo)});
  return state.setIn(['lawLedger', lawData.documentId], law);
};
const startEditing = (state, {payload: {documentId}}) => state.set('editingId', documentId);
const cancelEditing = (state) => state.set('editingId', null).set('saving', false);
const saveEditingStart = (state) => state.set('saving', true);
const saveEditingFinally = (state) => state.set('saving', false);
const saveEditing = (state, {payload: {updatedLaw}}) => {
  // updatedLaw is the return LawDto from the patch law service
  if (!state.get('editingId')) return state;
  let lawsContainer = state.get('laws').get(state.get('editingId')) ? 'laws' : 'lawLedger';
  return state.updateIn([lawsContainer, state.get('editingId')], (law) => law
    .set('adopted', updatedLaw.adopted)
    .set('adoptedDateAccuracy', updatedLaw.adoptedDateAccuracy)
    .set('appendedTo', updatedLaw.appendedTo)
    .set('comment', updatedLaw.comment)
    .set('customTitle', updatedLaw.customTitle)
    .set('deleted', updatedLaw.deleted.toString())
    .set('description', updatedLaw.description)
    .set('documentId', updatedLaw.documentId)
    .set('legislationType', updatedLaw.legislationType)
    .set('legislationYear', updatedLaw.year)
    .set('lineNumber', updatedLaw.lineNumber)
    .set('rank', updatedLaw.rank)
    .set('scanDate', updatedLaw.scanDate)
    .set('subject', updatedLaw.subject)
    .set('suppedBy', updatedLaw.suppedBy)
    .set('title', updatedLaw.title)
    .set('updatedBy', Person(updatedLaw.updatedBy))
    .set('updatedOn', updatedLaw.updated)
    .set('version', updatedLaw.version))
    .set('editingId', null);
};

const initHistory = (state, {payload: {filterText, filterYear, filterChapters, sort}}) => {
  return state.set('filterText', filterText)
    .set('filterYear', filterYear)
    .set('filterChapters', filterChapters)
    .set('sort', sort);
};

const updateHistory = (state, {payload: {replace}}) => updateHistoryUrl(state, replace);
const updateHistoryUrl = (state, replace = false) => {
  const filterText = state.filterText;
  const filterYear = state.filterYear;
  const filterChapters = state.filterChapters;
  const sort = state.sort;
  let urlParams = new URLSearchParams(window.location.search);
  urlParams.delete("filter");
  urlParams.delete("year");
  urlParams.delete("chapters");
  urlParams.delete("sort");
  if (filterText !== null && filterText !== "") {
    urlParams.set("filter", filterText);
  }
  if (filterYear !== null && filterYear !== "") {
    urlParams.set("year", filterYear);
  }
  for (const chapter of filterChapters) {
    urlParams.append("chapters", chapter);
  }
  if (sort !== null && sort.length > 0) {
    urlParams.set("sort", sort.map(s => `${s.columnName.toUpperCase()}${s.direction === "asc" ? "_ASC" : "_DESC"}`).join(","));
  }
  const queryString = "?" + urlParams.toString();
  const historyUrl = window.location.pathname + queryString + window.location.hash;
  if (replace) {
    replaceState({ filterText: filterText, filterYear: filterYear, filterChapters: filterChapters, sort: sort }, "", historyUrl);
  } else {
    pushState({ filterText: filterText, filterYear: filterYear, filterChapters: filterChapters, sort: sort }, "", historyUrl);
  }
  return state;
};

const setFilterText = (state, {payload: {filterText}}) => {
  let filteringText = state.filteringText;
  return updateHistoryUrl(state.set("filterText", filterText).set("filteringText", true), filteringText);
};
const setFilterYear = (state, {payload: {filterYear}}) => updateHistoryUrl(state.set("filterYear", filterYear).set("filteringText", false));
const setFilterChapters = (state, {payload: {filterChapters}}) => updateHistoryUrl(state.set("filterChapters", filterChapters).set("filteringText", false));
const setSort = (state, {payload: {sort}}) => updateHistoryUrl(state.set("sort", sort).set("filteringText", false));

const setShowDeletedLaws = (state, {payload:{showDeletedLaws}}) => state.set('showDeletedLaws', showDeletedLaws);

const reducer = handleActions({
  [actions.initLaw]: initLaw,
  [actions.initLawLedger]: initLawLedger,
  [actions.startEditing]: startEditing,
  [actions.cancelEditing]: cancelEditing,
  [actions.saveEditingSuccess]: saveEditing,
  [actions.saveEditingStart]: saveEditingStart,
  [actions.saveEditingFinally]: saveEditingFinally,
  [actions.setFilterText]: setFilterText,
  [actions.setFilterYear]: setFilterYear,
  [actions.setFilterChapters]: setFilterChapters,
  [actions.setShowDeletedLaws]: setShowDeletedLaws,
  [actions.setSort]: setSort,
  [actions.initHistory]: initHistory,
  [actions.updateHistory]: updateHistory
}, initialState);

export {State, initialState, Law};
export default reducer;
