import React from 'react';
import {MenuItem, Select} from "../../../common/components";

const SignageDistrictSelection = (props) => {
  const handleChange = (e) => {
    props.onChange(e.target.value);
  };

  return (
    <Select
      value={props.currentValue}
      onChange={handleChange}
      inputProps={{
        name: props.listName,
        id: props.listName,
      }}
    >
      {props.signageDistricts.map((category) => (
        <MenuItem key={category} value={category}>
          {category}
        </MenuItem>
      ))}
    </Select>
  );
};
export default SignageDistrictSelection;